<template>
  <div class="admin--stats">
    <p v-if="!stats">Loading...</p>
    <ul v-else>
      <li>{{stats.users.total}} total users</li>
      <li>
        {{stats.users.active}} active users
        <ul class="pl-5">
          <li>{{stats.users.admin}} are admins</li>
          <li>{{stats.users.plus}} have Plus membership</li>
          <li>{{stats.users.getStreakEmails}} get streak emails</li>
          <li>{{stats.users.getPromoEmails}} get promo emails</li>
        </ul>
      </li>
      <li>{{stats.users.pending}} pending users</li>
      <li>{{stats.users.deleted}} deleted users</li>
    </ul>
  </div>
</template>

<script>
import API from '@/services/api';

export default {
  name: 'AdminStats',
  data() {
    return {
      stats: null,
    };
  },
  created() {
    this.getStats();
  },
  methods: {
    async getStats() {
      try {
        const { data } = await API.admin.site.getStats();
        this.stats = data.stats;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
  },
};
</script>
