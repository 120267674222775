<template>
  <div class="admin--settings">
    <h2 class="title is-5">Notice</h2>
    <h3 class="subtitle is-7">Site notice shown in footer</h3>
    <div class="field">
      <b-switch v-model="noticeShow">Show notice</b-switch>
    </div>
    <b-field label="Content">
      <b-input v-model="noticeContent" type="text" />
    </b-field>
    <b-button type="is-primary" @click.stop="updateNotice">Save</b-button>

    <h2 class="title is-5 mt-6">Maintenance</h2>
    <h3 class="subtitle is-7">Site maintenance messaging settings</h3>
    <div class="field">
      <b-switch v-model="maintenanceEnabled">In maintenance</b-switch>
    </div>
    <b-field label="Message">
      <b-input v-model="maintenanceMessage" type="text" />
    </b-field>
    <b-field label="Access key">
      <b-input v-model="maintenanceAccessKey" type="text" />
    </b-field>
    <b-button type="is-primary" @click.stop="updateMaintenance">Save</b-button>

    <h2 class="title is-5 mt-6">Cache</h2>
    <h3 class="subtitle is-7">Reset caching of data and assets</h3>
    <div class="field">
      <b-switch v-model="resetCacheThumb">Reset thumbnail cache</b-switch>
    </div>
    <div class="field">
      <b-switch v-model="resetCacheAvatar">Reset user avatar cache</b-switch>
    </div>
    <b-button type="is-primary" @click.stop="resetCache">Submit</b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import API from '@/services/api';

export default {
  name: 'AdminSettings',
  data() {
    return {
      noticeContent: '',
      noticeShow: false,

      maintenanceEnabled: false,
      maintenanceMessage: '',
      maintenanceAccessKey: '',

      resetCacheThumb: false,
      resetCacheAvatar: false,
    };
  },
  created() {
    this.getMaintenance();
    this.noticeContent = this.notice.content;
    this.noticeShow = this.notice.globalShow ? this.notice.globalShow : false;
  },
  computed: {
    ...mapGetters({
      notice: 'site/notice',
    }),
  },
  methods: {
    async getMaintenance() {
      try {
        const { data } = await API.admin.site.getMaintenance();
        this.maintenanceMessage = data.maintenance.message;
        this.maintenanceEnabled = data.maintenance.enabled;
        this.maintenanceAccessKey = data.maintenance.accessKey;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
    async updateMaintenance() {
      try {
        await API.admin.site.updateMaintenance({
          message: this.maintenanceMessage,
          enabled: this.maintenanceEnabled,
          accessKey: this.maintenanceAccessKey,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        this.$toasted.show('Maintenance settings updated, reloading...', { duration: 2000 });
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
    async updateNotice() {
      try {
        await API.admin.site.updateNotice({
          show: this.noticeShow,
          content: this.noticeContent,
        });
        this.$store.dispatch('site/getNotice');
        this.$toasted.show('Notice updated!', { duration: 2000 });
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
    async resetCache() {
      try {
        await API.admin.site.resetCache({
          resetThumbnails: this.resetCacheThumb,
          resetAvatars: this.resetCacheAvatar,
        });
        if (this.resetCacheThumb) {
          this.$toasted.show('Thumbnail cache reset!', { duration: 2000 });
        }
        if (this.resetCacheAvatar) {
          this.$toasted.show('User avatar cache reset!', { duration: 2000 });
        }
        this.resetCacheThumb = false;
        this.resetCacheAvatar = false;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
  },
};
</script>
