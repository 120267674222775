<template>
  <div class="editor container is-fullhd">
    <div class="level">
      <div class="level-left">
        <h1 class="title">{{pageTitle}}</h1>
      </div>
      <div class="level-right">
        <router-link to="/admin/blog" class="button">Back</router-link>

        <b-button type="is-primary" @click.stop="save">Save</b-button>

        <b-button v-if="status === 'published'" type="is-danger" @click.stop="unpublish">
          Unpublish
        </b-button>
        <b-button v-else type="is-success" @click.stop="publish">
          Save &amp; Publish
        </b-button>
      </div>
    </div>
    <section class="columns">
      <div class="column is-half">
        <b-field label="Title" :message="getError('title')">
          <b-input v-model="title" @blur="setSlug" />
        </b-field>
        <b-field label="Slug" :message="getError('email')">
          <b-input v-model="slug" @blur="onSlugBlur" />
        </b-field>
        <b-field label="Abstract" :message="getError('abstract')">
          <textarea v-model="abstract" class="textarea" maxlength="1000" ref="abstract"></textarea>
        </b-field>
      </div>

      <div class="column is-half">
        <figure class="image is-2by1">
          <img :src="imageUrl" alt="Featured image" />
        </figure>
        <b-field label="Image URL">
          <b-input v-model="imageUrl" />
        </b-field>
      </div>
    </section>

    <hr>

    <section class="columns">
      <div class="column is-half">
        <textarea v-model="content" class="textarea" @paste="handlePaste" ref="content"></textarea>
      </div>
      <div class="column is-half">
        <div v-html="markdown"></div>
      </div>
    </section>
  </div>
</template>

<script>
import marked from 'marked';
import { required } from 'vuelidate/lib/validators';
import { getValidationError } from '@/services/util';
import API from '@/services/api';

export default {
  name: 'Editor',
  data() {
    return {
      loading: true,
      submitting: false,
      editing: false,
      article: null,

      slugSet: false,
      unsavedChanges: false,

      content: '',
      abstract: '',
      title: '',
      imageUrl: '',
      slug: '',
      status: 'draft',
      publishedAt: null,
    };
  },
  validations: {
    title: {
      required,
    },
    slug: {
      required,
    },
    abstract: {
      required,
    },
  },
  created() {
    const id = parseInt(this.$route.params.id, 10);
    if (!Number.isNaN(id)) {
      this.editing = true;
      this.getArticle(id);
    } else {
      this.loading = false;
    }
  },
  computed: {
    pageTitle() {
      return this.editing ? 'Article Editor - Update' : 'Article Editor - New';
    },
    markdown() {
      return marked(this.content);
    },
  },
  watch: {
    content() {
      if (!this.unsavedChanges && !this.skipNextChange) {
        this.$store.dispatch('site/preventNavigation', 'You have unsaved changes, leave the page?');
        this.unsavedChanges = true;
      }
      this.skipNextChange = false;
    },
  },
  methods: {
    async getArticle(id) {
      try {
        const { data } = await API.blog.articles.get(id);
        this.article = data.article;
        this.content = this.article.content;
        this.abstract = this.article.abstract;
        this.title = this.article.title;
        this.imageUrl = this.article.imageUrl;
        this.slug = this.article.slug;
        this.status = this.article.status;
        this.publishedAt = this.article.publishedAt;
        this.slugSet = true;
        this.skipNextChange = true;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.loading = false;
    },
    onSlugBlur() {
      this.slugSet = !!this.slug.length;
    },
    setSlug() {
      if (this.slugSet) return;
      this.slug = this.title.toLowerCase().replace(' ', '-');
    },
    async save() {
      if (this.submitting) return;

      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.submitting = true;

      const payload = {
        content: this.content,
        abstract: this.abstract,
        title: this.title,
        imageUrl: this.imageUrl,
        slug: this.slug,
        status: this.status,
      };

      if (!this.editing) {
        payload.status = 'draft';
      }

      try {
        let res;
        if (this.editing) {
          res = await API.admin.blog.articles.update(this.article.id, payload);
        } else {
          res = await API.admin.blog.articles.create(payload);
        }
        this.article = res.data.article;
        this.editing = true;
        this.unsavedChanges = false;
        this.$store.dispatch('site/preventNavigation', false);
        this.$toasted.show('Article saved', { duration: 2000 });
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.submitting = false;
    },
    async publish() {
      await this.save();
      if (this.unsavedChanges) return; // failed to save
      this.submitting = true;
      try {
        const { data } = await API.admin.blog.articles.publish(this.article.id);
        this.article = data.article;
        this.publishedAt = this.article.publishedAt;
        this.status = this.article.status;
        this.$toasted.success('Article published!', { duration: 2000 });
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.submitting = false;
    },
    async unpublish() {
      this.submitting = true;
      try {
        const { data } = await API.admin.blog.articles.unpublish(this.article.id);
        this.article = data.article;
        this.status = this.article.status;
        this.$toasted.show('Article unpublished!', { duration: 2000 });
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.submitting = false;
    },
    getError(field) {
      if (!this.$v[field]) return '';
      if (!this.$v[field].$dirty) return '';
      return getValidationError(this.$v[field]);
    },
    handlePaste(e) {
      if (e.clipboardData && e.clipboardData.items
        && e.clipboardData.items[0].type.indexOf('image') !== -1) {
        const file = e.clipboardData.items[0].getAsFile();

        const pos = this.$refs.content.selectionStart;
        const insert = `![Uploading...](${Date.now()})`;
        this.content = `${this.content.slice(0, pos)}${insert}${this.content.slice(pos)}`;

        this.uploadFile(file, insert);
      }
    },
    async uploadFile(file, insert) {
      try {
        const { data } = await API.admin.blog.upload(file);
        this.content = this.content.replace(
          insert, `![${data.image.filename}](${data.image.imageUrl})`,
        );
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  height: 100%;
  max-height: none;
}
</style>
