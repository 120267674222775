<template>
  <div class="admnin container is-fullhd">
    <h1 class="title">Admin</h1>
    <section>
      <div class="tabs">
        <ul>
          <li
            v-for="(tab, i) in tabs"
            :key="'tab-' + i"
            :class="{'is-active': $route.fullPath === tab.path}">
            <router-link :to="tab.path">{{tab.name}}</router-link>
          </li>
        </ul>
      </div>
      <component :is="currentTabComponent" class="tab-content" />
    </section>
  </div>
</template>

<script>
import Stats from '@/components/Admin/Stats.vue';
import Settings from '@/components/Admin/Settings.vue';
import Blog from '@/components/Admin/Blog.vue';

export default {
  name: 'Admin',
  components: {
    Stats,
    Settings,
    Blog,
  },
  data() {
    return {
      tabs: [
        { path: '/admin', name: 'Stats', component: Stats },
        { path: '/admin/settings', name: 'Settings', component: Settings },
        { path: '/admin/blog', name: 'Blog', component: Blog },
      ],
    };
  },
  computed: {
    currentTab() {
      let tab = this.tabs[0];
      const ts = this.tabs.filter((t) => this.$route.fullPath === t.path);
      if (ts.length === 1) tab = ts[0]; // eslint-disable-line prefer-destructuring
      return tab;
    },
    currentTabComponent() {
      return this.currentTab.component;
    },
  },
};
</script>
