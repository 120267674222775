<template>
  <div class="admin--blog">

    <div class="level">
      <div class="level-left">
        {{articles.length}} Articles
      </div>
      <div class="level-right">
        <router-link class="button is-primary" to="/editor">New</router-link>
      </div>
    </div>

    <table class="table is-fullwidth is-striped">
      <thead>
        <tr>
          <th>Title</th>
          <th>Created At</th>
          <th>Updated At</th>
          <th>Status</th>
          <th>Published At</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-for="(article, i) in articles" :key="i">
        <tr>
          <td>{{article.title}}</td>
          <td>{{article.createdAt}}</td>
          <td>{{article.updatedAt}}</td>
          <td>
            <span
              class="tag is-light"
              :class="getTagClasses(article.status)">
              {{article.status}}
            </span>
          </td>
          <td>{{article.publishedAt}}</td>
          <td>
            <router-link :to="'/editor/' + article.id">Edit</router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import API from '@/services/api';

export default {
  name: 'AdminBlog',
  data() {
    return {
      articles: [],
      editor: '',
    };
  },
  created() {
    this.loadArticles();
  },
  methods: {
    async loadArticles() {
      try {
        const { data } = await API.admin.blog.articles.list({
          include: 'draft,deleted',
          pageSize: 100,
          orderBy: 'createdAt-desc',
        });
        this.articles = data.articles;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.loading = false;
    },
    getTagClasses(status) {
      if (status === 'published') return 'is-success';
      if (status === 'deleted') return 'is-danger';
      return 'is-warning';
    },
  },
};
</script>
