<template>
  <div class="article container is-fullhd">
    <section class="hero has-text-centered">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">{{title}}</h1>
        </div>
      </div>
    </section>
    <section class="container is-max-desktop article__body">
      <div class="level meta mb-2">
        <div class="level-left">{{articleDate}}</div>
      </div>
      <figure class="image is-2by1 mb-6" v-if="article">
        <img :src="article.imageUrl" :alt="title" v-if="article"/>
      </figure>
      <p v-if="loading">Loading...</p>
      <div v-else v-html="markdown" class="article__content"></div>
    </section>
  </div>
</template>

<script>
import marked from 'marked';
import API from '@/services/api';
import dayjs from '@/services/dayjs';

export default {
  name: 'Article',
  data() {
    return {
      loading: true,
      slug: '',
      article: null,
    };
  },
  created() {
    this.slug = this.$route.params.slug;
    this.loadArticle();
  },
  computed: {
    title() {
      if (this.article) return this.article.title;
      return this.slug[0].toUpperCase() + this.slug.slice(1);
    },
    markdown() {
      if (!this.article) return '';
      return marked(this.article.content);
    },
    articleDate() {
      if (!this.article) return '';
      const pa = dayjs(this.article.publishedAt);
      if (!pa.isValid()) return '';
      return pa.format('Do MMMM YYYY');
    },
  },
  methods: {
    async loadArticle() {
      try {
        const { data } = await API.blog.articles.getBySlug(this.slug);
        this.article = data.article;
      } catch (e) {
        const error = API.handleError(e);
        if (error) {
          this.$toasted.error(error.message, { duration: 2000 });
          if (error.status === 404) this.$router.push('/404');
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.meta {
  font-size: 1rem;
  color: #999;
}
.hero-body {
  padding: 2rem 1rem;
}
</style>
