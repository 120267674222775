<template>
  <div class="blog container is-fullhd">
    <h1 class="title">Blog</h1>
    <section class="columns">
      <div class="column is-one-third" v-for="(article, i) in articles" :key="i">
        <router-link :to="`/blog/${article.slug}`">
          <div class="card">
            <div class="card-image">
              <figure class="image is-2by1">
                <img :src="article.imageUrl" :alt="article.title">
              </figure>
            </div>
            <div class="card-content">
              <h2 class="title is-4">{{article.title}}</h2>
              <div class="content" v-html="processMarkdown(article.abstract)"></div>
              <div class="overlay"></div>
            </div>
          </div>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import marked from 'marked';
import API from '@/services/api';

export default {
  name: 'Blog',
  data() {
    return {
      loading: true,
      articles: [],
    };
  },
  created() {
    this.loadArticles();
  },
  methods: {
    async loadArticles() {
      try {
        const { data } = await API.blog.articles.list({ pageSize: 18 });
        this.articles = data.articles;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.loading = false;
    },
    processMarkdown(md) {
      return marked(md);
    },
  },
};
</script>

<style lang="scss" scoped>
.blog .card {
  .card-content {
    position: relative;

    .content {
      height: 250px;
    }

    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: 0 -8em 3em -3em white inset;
    }
  }
}
</style>
